export const firstCarouselList = [
  {
    id: 1,
    name: "VR ArchViz",
    url: "https://harmonity.com/videos/archviz.webm",
    previewUrl: "/assets/carousel/ArchViz_Poster.png",
    text: [
      `VR project presenting an apartment with functionalities such as changing furniture, changing the wall color, moving chairs and glasses, and opening doors.`,
      `
        The utility lies in allowing potential buyers to virtually tour the apartment and try out different arrangements and decorations without physical presence.`,
    ],
  },
  {
    id: 2,
    name: "AXYZ - CNC masina",
    url: "https://harmonity.com/videos/axys.webm",
    previewUrl: "/assets/carousel/AXYZ_Poster_3.png",
    text: [
      `This project aims to visually demonstrate the production process of CNC machines by showcasing their manufacturing.`,
      ` The display provides an interactive and informative experience, showcasing the precision and efficiency of modern manufacturing techniques.`,
    ],
  },
  {
    id: 3,
    name: "Bugatti",
    url: "https://harmonity.com/videos/bugatti.webm",
    previewUrl: "/assets/carousel/Bugatti_Poster_2.png",
    text: [
      `This project involves creating a 3D animation of a Bugatti automobile, showcasing both its interior and exterior design features.`,
      ` The animation provides a realistic and detailed representation of the car's sleek and high-performance design, highlighting its unique features and engagingly promoting the brand.`,
    ],
  },
  {
    id: 4,
    name: "Cosmic Faction",
    url: "https://harmonity.com/videos/cosmicfaction.webm",
    previewUrl: "/assets/carousel/Cosmic_Faction_Poster_3.png",
    text: [
      `This project, also designed as a VR experience, is a metaverse created in Godot. It includes mini-games, such as pinball and spray painting, and players can collect crystals throughout.`,
      `Our team joined their expertise in game development, virtual reality, and cryptocurrency to create a unique and engaging experience for players in Cosmic Factions.`,
    ],
  },
  {
    id: 5,
    name: "Crowd Simulation",
    url: "https://harmonity.com/videos/crowdsimulation.webm",
    previewUrl: "/assets/carousel/Crowd_Simulation_Poster.png",
    text: [
      `This project involves the integration of VFX and 3D animation with live-action drone footage for use in a film.`,
      ` It required camera matching and crowd simulation techniques to create realistic scenes while implementing 3D elements.`,
    ],
  },
  {
    id: 6,
    name: "VR ArchViz",
    url: "https://harmonity.com/videos/archviz.webm",
    previewUrl: "/assets/carousel/ArchViz_Poster.png",
    text: [
      `VR project presenting an apartment with functionalities such as changing furniture, changing the wall color, moving chairs and glasses, and opening doors.`,
      `
        The utility lies in allowing potential buyers to virtually tour the apartment and try out different arrangements and decorations without physical presence.`,
    ],
  },
  {
    id: 7,
    name: "AXYZ - CNC masina",
    url: "https://harmonity.com/videos/axys.webm",
    previewUrl: "/assets/carousel/AXYZ_Poster_3.png",
    text: [
      `This project aims to visually demonstrate the production process of CNC machines by showcasing their manufacturing.`,
      ` The display provides an interactive and informative experience, showcasing the precision and efficiency of modern manufacturing techniques.`,
    ],
  },
  {
    id: 8,
    name: "Bugatti",
    url: "https://harmonity.com/videos/bugatti.webm",
    previewUrl: "/assets/carousel/Bugatti_Poster_2.png",
    text: [
      `This project involves creating a 3D animation of a Bugatti automobile, showcasing both its interior and exterior design features.`,
      ` The animation provides a realistic and detailed representation of the car's sleek and high-performance design, highlighting its unique features and engagingly promoting the brand.`,
    ],
  },
];

export const secondCarouselList = [
  {
    id: 1,
    name: "Veg Concept",
    url: "https://harmonity.com/videos/vegconcept.webm",
    previewUrl: "/assets/carousel/Veg_Concept_Poster.png",
    text: [
      `The project is a visualization of luxury tables in specific spaces, matching 3D models with photographs.`,
      `It provides a realistic preview of the final result, helping clients make informed decisions and giving designers a powerful tool to showcase their work.`,
    ],
  },
  {
    id: 2,
    name: "ExpressLogic",
    url: "https://harmonity.com/videos/expresslogic.webm",
    previewUrl: "/assets/carousel/Express_Logic_Poster.png",
    text: [
      `This project involves the creation of an advertisement for an international trade show containing 3D and motion graphics.`,
      `3D and motion graphics are extremely attractive to viewers and often catch their attention more than other advertisements.`,
    ],
  },
  {
    id: 3,
    name: "Fruition Juice",
    url: "https://harmonity.com/videos/fruition.webm",
    previewUrl: "/assets/carousel/Fruition_Poster.png",
    text: [
      `This project involves creating a 3D animation of a fruit for a juice commercial, showcasing its visual appeal and freshness.`,
      `The animation effectively highlights the fruit's unique features and taste to promote the juice product in a captivating way.`,
    ],
  },
  {
    id: 4,
    name: "HSI professional - hair straightener",
    url: "https://harmonity.com/videos/hsiprofessional.webm",
    previewUrl: "/assets/carousel/HSI_professional_Poster_1.png",
    text: [
      `This project promotes the HSI Professional hair straightener by creating a 3D animation showcasing its design and features.`,
      `Through the animation, customers better understand the product's functionality and benefits.`,
    ],
  },
  {
    id: 5,
    name: "RAM",
    previewUrl: "/assets/carousel/RAM_Poster.png",
    url: "https://harmonity.com/videos/ram.webm",
    text: [
      `This project involves creating a 3D animation of a RAM Dodge automobile, showcasing both the interior and exterior of the vehicle.`,
      ` Animations like this provide a detailed and realistic representation of the vehicle's design and features.`,
    ],
  },
  {
    id: 6,
    name: "Veg Concept",
    url: "https://harmonity.com/videos/vegconcept.webm",
    previewUrl: "/assets/carousel/Veg_Concept_Poster.png",
    text: [
      `The project is a visualization of luxury tables in specific spaces, matching 3D models with photographs.`,
      `It provides a realistic preview of the final result, helping clients make informed decisions and giving designers a powerful tool to showcase their work.`,
    ],
  },
  {
    id: 7,
    name: "ExpressLogic",
    url: "https://harmonity.com/videos/expresslogic.webm",
    previewUrl: "/assets/carousel/Express_Logic_Poster.png",
    text: [
      `This project involves the creation of an advertisement for an international trade show containing 3D and motion graphics.`,
      `3D and motion graphics are extremely attractive to viewers and often catch their attention more than other advertisements.`,
    ],
  },
  {
    id: 8,
    name: "Fruition Juice",
    url: "https://harmonity.com/videos/fruition.webm",
    previewUrl: "/assets/carousel/Fruition_Juice_Poster_1.png",
    text: [
      `This project involves creating a 3D animation of a fruit for a juice commercial, showcasing its visual appeal and freshness.`,
      `The animation effectively highlights the fruit's unique features and taste to promote the juice product in a captivating way.`,
    ],
  },
];

export const thirdCarouselList = [
  {
    id: 1,
    name: "Fruition",
    previewUrl: "/assets/carousel/Fruition_Juice_Poster_1.png",
    url: "https://harmonity.com/videos/loverslimun.webm",
    text: [
      `This project involves creating a 3D animation of a fruit for a juice commercial, showcasing its visual appeal and freshness.`,
      `The animation effectively highlights the fruit's unique features and taste to promote the juice product in a captivating way. `,
    ],
  },
  {
    id: 2,
    name: "Microsoft-Azure-RTOS-ThreadX",
    url: "https://harmonity.com/videos/Microsoft-Azure-RTOS-ThreadX.webm",
    previewUrl: "/assets/carousel/Microsoft_Poster_2.png",
    text: [
      `This project is a video presentation for corporate event as an essential tool for effectively 	communicating a brand's message.`,
      `Video presentations focusing on storytelling and high-quality visuals offer a dynamic and 	engaging way to captivate the audience.`,
    ],
  },
  {
    id: 3,
    name: "Solutions for websites",
    url: "https://harmonity.com/videos/websitetemplate.webm",
    previewUrl: "/assets/carousel/Custom_Animations_Poster.png",
    text: [
      `Custom-made website solutions tailored to business needs ensure a unique and optimized 	online presence that stands out.`,
      ` A personalized approach guarantees client satisfaction by delivering websites that exceed 	expectations.`,
    ],
  },
  {
    id: 4,
    name: "Sabac fortress",
    url: "https://harmonity.com/videos/vr-tvrdjava.webm",
    previewUrl: "/assets/carousel/Sabac_Fortress_Poster_2.png",
    text: [
      `VR project representing the Šabac Fortress from the 15th century in life-size, based on historical data and Hartmann's illustrations. `,
      `The project includes functionality such as archery, sword fighting, and a lot of historical information, as well as the use of many assets.`,
    ],
  },
  {
    id: 5,
    name: "VR ArchViz Functionalities",
    url: "https://harmonity.com/videos/vr-archviz-functionalities.webm",
    previewUrl: "assets/carousel/VR_ArchViz_Poster_2.png",
    text: [``, ``],
  },
  {
    id: 6,
    name: "Fruition",
    previewUrl: "/assets/carousel/Fruition_Juice_Poster_1.png",
    url: "https://harmonity.com/videos/loverslimun.webm",
    text: [
      `This project involves creating a 3D animation of a fruit for a juice commercial, showcasing its visual appeal and freshness.`,
      `The animation effectively highlights the fruit's unique features and taste to promote the juice product in a captivating way. `,
    ],
  },
  {
    id: 7,
    name: "Microsoft-Azure-RTOS-ThreadX",
    url: "https://harmonity.com/videos/Microsoft-Azure-RTOS-ThreadX.webm",
    previewUrl: "/assets/carousel/Microsoft_Poster_2.png",
    text: [
      `This project is a video presentation for corporate event as an essential tool for effectively 	communicating a brand's message.`,
      `Video presentations focusing on storytelling and high-quality visuals offer a dynamic and 	engaging way to captivate the audience.`,
    ],
  },
  {
    id: 8,
    name: "Solutions for websites",
    url: "https://harmonity.com/videos/websitetemplate.webm",
    previewUrl: "/assets/carousel/Custom_Animations_Poster.png",
    text: [
      `Custom-made website solutions tailored to business needs ensure a unique and optimized 	online presence that stands out.`,
      ` A personalized approach guarantees client satisfaction by delivering websites that exceed 	expectations.`,
    ],
  },
];

export const mobileVieoList = [
  {
    id: 1,
    name: "VR ArchViz",
    url: "https://harmonity.com/videos/archviz.webm",
    previewUrl: "/assets/carousel/ArchViz_Poster.png",
    text: [
      `VR project presenting an apartment with functionalities such as changing furniture, changing the wall color, moving chairs and glasses, and opening doors.`,
      `
        The utility lies in allowing potential buyers to virtually tour the apartment and try out different arrangements and decorations without physical presence.`,
    ],
  },
  {
    id: 2,
    name: "AXYZ - CNC masina",
    url: "https://harmonity.com/videos/axys.webm",
    previewUrl: "/assets/carousel/AXYZ_Poster_3.png",
    text: [
      `This project aims to visually demonstrate the production process of CNC machines by showcasing their manufacturing.`,
      ` The display provides an interactive and informative experience, showcasing the precision and efficiency of modern manufacturing techniques.`,
    ],
  },
  {
    id: 3,
    name: "Bugatti",
    url: "https://harmonity.com/videos/bugatti.webm",
    previewUrl: "/assets/carousel/Bugatti_Poster_2.png",
    text: [
      `This project involves creating a 3D animation of a Bugatti automobile, showcasing both its interior and exterior design features.`,
      ` The animation provides a realistic and detailed representation of the car's sleek and high-performance design, highlighting its unique features and engagingly promoting the brand.`,
    ],
  },
  {
    id: 4,
    name: "Cosmic Faction",
    url: "https://harmonity.com/videos/cosmicfaction.webm",
    previewUrl: "/assets/carousel/Cosmic_Faction_Poster_3.png",
    text: [
      `This project, also designed as a VR experience, is a metaverse created in Godot. It includes mini-games, such as pinball and spray painting, and players can collect crystals throughout.`,
      `Our team joined their expertise in game development, virtual reality, and cryptocurrency to create a unique and engaging experience for players in Cosmic Factions.`,
    ],
  },
  {
    id: 5,
    name: "Crowd Simulation",
    url: "https://harmonity.com/videos/crowdsimulation.webm",
    previewUrl: "/assets/carousel/Crowd_Simulation_Poster.png",
    text: [
      `This project involves the integration of VFX and 3D animation with live-action drone footage for use in a film.`,
      ` It required camera matching and crowd simulation techniques to create realistic scenes while implementing 3D elements.`,
    ],
  },
  {
    id: 6,
    name: "Veg Concept",
    url: "https://harmonity.com/videos/vegconcept.webm",
    previewUrl: "/assets/carousel/Veg_Concept_Poster.png",
    text: [
      `The project is a visualization of luxury tables in specific spaces, matching 3D models with photographs.`,
      `It provides a realistic preview of the final result, helping clients make informed decisions and giving designers a powerful tool to showcase their work.`,
    ],
  },
  {
    id: 7,
    name: "ExpressLogic",
    url: "https://harmonity.com/videos/expresslogic.webm",
    previewUrl: "/assets/carousel/Express_Logic_Poster.png",
    text: [
      `This project involves the creation of an advertisement for an international trade show containing 3D and motion graphics.`,
      `3D and motion graphics are extremely attractive to viewers and often catch their attention more than other advertisements.`,
    ],
  },
  {
    id: 8,
    name: "Fruition Juice",
    url: "https://harmonity.com/videos/fruition.webm",
    previewUrl: "/assets/carousel/Fruition_Poster.png",
    text: [
      `This project involves creating a 3D animation of a fruit for a juice commercial, showcasing its visual appeal and freshness.`,
      `The animation effectively highlights the fruit's unique features and taste to promote the juice product in a captivating way.`,
    ],
  },
  {
    id: 9,
    name: "HSI professional - hair straightener",
    url: "https://harmonity.com/videos/hsiprofessional.webm",
    previewUrl: "/assets/carousel/HSI_professional_Poster_1.png",
    text: [
      `This project promotes the HSI Professional hair straightener by creating a 3D animation showcasing its design and features.`,
      `Through the animation, customers better understand the product's functionality and benefits.`,
    ],
  },
  {
    id: 10,
    name: "RAM",
    previewUrl: "/assets/carousel/RAM_Poster.png",
    url: "https://harmonity.com/videos/ram.webm",
    text: [
      `This project involves creating a 3D animation of a RAM Dodge automobile, showcasing both the interior and exterior of the vehicle.`,
      ` Animations like this provide a detailed and realistic representation of the vehicle's design and features.`,
    ],
  },

  {
    id: 11,
    name: "Fruition",
    previewUrl: "/assets/carousel/Fruition_Juice_Poster_1.png",
    url: "https://harmonity.com/videos/loverslimun.webm",
    text: [
      `This project involves creating a 3D animation of a fruit for a juice commercial, showcasing its visual appeal and freshness.`,
      `The animation effectively highlights the fruit's unique features and taste to promote the juice product in a captivating way. `,
    ],
  },
  {
    id: 12,
    name: "Microsoft-Azure-RTOS-ThreadX",
    url: "https://harmonity.com/videos/Microsoft-Azure-RTOS-ThreadX.webm",
    previewUrl: "/assets/carousel/Microsoft_Poster_2.png",
    text: [
      `This project is a video presentation for corporate event as an essential tool for effectively 	communicating a brand's message.`,
      `Video presentations focusing on storytelling and high-quality visuals offer a dynamic and 	engaging way to captivate the audience.`,
    ],
  },
  {
    id: 13,
    name: "Solutions for websites",
    url: "https://harmonity.com/videos/websitetemplate.webm",
    previewUrl: "/assets/carousel/Custom_Animations_Poster.png",
    text: [
      `Custom-made website solutions tailored to business needs ensure a unique and optimized 	online presence that stands out.`,
      ` A personalized approach guarantees client satisfaction by delivering websites that exceed 	expectations.`,
    ],
  },
  {
    id: 14,
    name: "Sabac fortress",
    url: "https://harmonity.com/videos/vr-tvrdjava.webm",
    previewUrl: "/assets/carousel/Sabac_Fortress_Poster_2.png",
    text: [
      `VR project representing the Šabac Fortress from the 15th century in life-size, based on historical data and Hartmann's illustrations. `,
      `The project includes functionality such as archery, sword fighting, and a lot of historical information, as well as the use of many assets.`,
    ],
  },
  {
    id: 15,
    name: "VR ArchViz Functionalities",
    url: "https://harmonity.com/videos/vr-archviz-functionalities.webm",
    previewUrl: "assets/carousel/VR_ArchViz_Poster_2.png",
    text: [
      `VR project presenting an apartment with functionalities such as changing furniture, changing the wall color, moving chairs and glasses, and opening doors.`,
      `
    The utility lies in allowing potential buyers to virtually tour the apartment and try out different arrangements and decorations without physical presence.`,
    ],
  },
  {
    id: 16,
    name: "VR ArchViz",
    url: "https://harmonity.com/videos/archviz.webm",
    previewUrl: "/assets/carousel/ArchViz_Poster.png",
    text: [
      `VR project presenting an apartment with functionalities such as changing furniture, changing the wall color, moving chairs and glasses, and opening doors.`,
      `
        The utility lies in allowing potential buyers to virtually tour the apartment and try out different arrangements and decorations without physical presence.`,
    ],
  },
  {
    id: 17,
    name: "AXYZ - CNC masina",
    url: "https://harmonity.com/videos/axys.webm",
    previewUrl: "/assets/carousel/AXYZ_Poster_3.png",
    text: [
      `This project aims to visually demonstrate the production process of CNC machines by showcasing their manufacturing.`,
      ` The display provides an interactive and informative experience, showcasing the precision and efficiency of modern manufacturing techniques.`,
    ],
  },
  {
    id: 18,
    name: "Bugatti",
    url: "https://harmonity.com/videos/bugatti.webm",
    previewUrl: "/assets/carousel/Bugatti_Poster_2.png",
    text: [
      `This project involves creating a 3D animation of a Bugatti automobile, showcasing both its interior and exterior design features.`,
      ` The animation provides a realistic and detailed representation of the car's sleek and high-performance design, highlighting its unique features and engagingly promoting the brand.`,
    ],
  },
  {
    id: 19,
    name: "Cosmic Faction",
    url: "https://harmonity.com/videos/cosmicfaction.webm",
    previewUrl: "/assets/carousel/Cosmic_Faction_Poster_3.png",
    text: [
      `This project, also designed as a VR experience, is a metaverse created in Godot. It includes mini-games, such as pinball and spray painting, and players can collect crystals throughout.`,
      `Our team joined their expertise in game development, virtual reality, and cryptocurrency to create a unique and engaging experience for players in Cosmic Factions.`,
    ],
  },
  {
    id: 20,
    name: "Crowd Simulation",
    url: "https://harmonity.com/videos/crowdsimulation.webm",
    previewUrl: "/assets/carousel/Crowd_Simulation_Poster.png",
    text: [
      `This project involves the integration of VFX and 3D animation with live-action drone footage for use in a film.`,
      ` It required camera matching and crowd simulation techniques to create realistic scenes while implementing 3D elements.`,
    ],
  },
  {
    id: 21,
    name: "Veg Concept",
    url: "https://harmonity.com/videos/vegconcept.webm",
    previewUrl: "/assets/carousel/Veg_Concept_Poster.png",
    text: [
      `The project is a visualization of luxury tables in specific spaces, matching 3D models with photographs.`,
      `It provides a realistic preview of the final result, helping clients make informed decisions and giving designers a powerful tool to showcase their work.`,
    ],
  },
];

export const whoWeAreTexts = {
  1: "Becoming a reference in the near future in all services we provide is our clear vision.",
  2: "Digitalization, Innovation, Improvement and cost reduction describe our mission. Being a unique partner in all IT fields and providing project delivery excellence is our target with all our clients.",
  3: `By accompanying companies from their need definition, through the delivery and management of the Project Lifecycle, we guarantee the success of the project efficiency and maximization of profit from our client's investment.`,
};

export const solutionsTexts = {
  1: `Harmonity Solutions is the reliable partner for improving your business and overcoming the challenges you face.`,
  2: `Outsourcing IT services has many operational advantages and brings cost benefits. Not only will you save valuable resources with effective, fast solutions, but you will also save time by having someone already well-versed and experienced in resolving your needs.`,
  3: `Thanks to our partners, we are able to include cyber security services in our offer. ​

The main focus is on ensuring the protection of our clients by preventing unauthorized access and external attacks on their computer systems and networks. ​
With our partner's LMS - learning management sistem, in addition to protection, your employee can get the necessary education. The training is based on the needs identified after testing cybersecurity behavior in your company. `,
  4: `With our professional services, we will help you address the opportunities and challenges of your network and infrastructure to ensure the best use of resources, appropriate operating, and protection against data loss.`,
  5: `In an ever-expanding digital global marketplace, it is necessary to have an online presence. Our team will be able to develop websites for any needs your company may have. We optimize the content to make your company visible on all platforms, give your business a modern presentation, or create an online store to improve your sale.`,
  6: `Our team possesses the deep technical knowledge to build products that are easy to use, functional, and secure. We build software that delivers long-term results for your business. `,
  7: `Everyone can make a game, but not everyone can put it on the web. We use engines that bring the ultimate gaming experience. ​

With our portfolio, we are becoming a Web 3.0 reference. `,
  8: `When it comes to AR and VR, what sets us apart are custom-made solutions.​​
Thanks to the customized code developed by our VR developers, we create unique and high-quality products.​​
This way, we are taking you far further than Plug and Play apps based simply on content export.`,
};

export const pixels2pixelsTexts = {
  1: `Pixels2Pixels is Harmonity Group’s creative part, which allows us to create a great variety of products.`,
  2: `We create virtual environments for various VR / AR headsets  such as Microsoft Hololens, Meta Quest, HTC VR  devices. ​

  They can be a recreation of existing surroundings or a presentation of what you envisioned. `,
  3: `3D visualizations that we create are high-quality digital content that benefits numerous industries. ​

  Through 3D visualization, we are helping you to communicate your ideas to clients and get immediate feedback, avoiding waste of resources and time on changes in the project implementation process. `,
  4: `In addition to being able to create motion graphics, character animation and visual effects, thanks to post-production, we are in a position to offer you a final product that combines all of the above. `,
  5: `Our creative team will make your business stand out, providing you with visual content that communicates your brand to clients in the best way. `,
  6: `Knowing the importance of marketing as well as the presence on the internet and social networks, we have created an overall digital marketing service.​`,
};

export const businessconsultingTetxts = {
  1: `With senior and highly skilled consultants, the Business consulting entity will provide you guidance in enterprise and projects structures setup.`,
  2: `Our experts accompany your projects to success by helping you define, maintain, and ensure project management standards across your organization.    ​

  Through business analysis, we help you to size and use improvement opportunities, just as we successfully guide you through the processes of necessary change that your business is facing.`,
  3: `Whether you are experiencing challenges with company culture and internal processes or need a new business strategy to reflect your envisioned future, we are here to provide you with guidance.`,
  4: `Digital transformation includes cultural, organizational, and operational changes inside the company.​
  With our guidance and digital transformation strategy, we are covering all these aspects and transforming your non-digital business to meet the evolving market and customer expectations.`,
};

export const academyTetxts = {
  1: `Harmonity Academy is an accredited part of Harmonity Group allowed to provide courses and certificates to professionals. `,

  2: `We offer a Project Management Trainer certificate accredited by IMP and Autodesk software training by experienced certified professionals.  Our team of experts has years of experience in their respective fields, and they're always eager to share their insights. Ready to learn?`,
};

export const rndTetxts = {
  1: `Wishing to always be on top of technology and innovation, we invest in R&D, either in continuous improvement of our services or for new emerging technologies. `,

  2: `We are currently focusing our investments on a product that combines new technologies - VR, metahuman technology, and ChatGPT.   The top is where you, too, will find yourself if you partner up with us. Enable your organization to drive innovation and create value for your businesses and consumers.`,
};
